import { ref } from "vue";
import { blackApi, cancelBlackApi } from "@/api/message";

export const useVisiter = () => {
  
  // 处理操作标签
  const actionList:any = {
    'jt': { name: '截图', color: '#F9EDEB' },
    'xz': { name: '下载', color: '#D8E9FA' },
    'dy': { name: '打印', color: '#F5F6E3' },
    'zf': { name: '转发', color: '#F6E3F5' }
  }
  const handleAction = (item: any) => {
    let action:any = []
    Object.keys(actionList).map((i) => {
      if (item[i] === 1) {
        action.push(actionList[i])
      }
    })
    return action
  }

  // 添加/删除 黑名单
  const editLoading = ref(false)
  const editBlack = (type: string, row: any) => {
    if (editLoading.value) return
    return new Promise(async (resolve, reject) => {
      let api = type === 'add' ? blackApi : cancelBlackApi
      try {
        editLoading.value = true
        const result = await api({ lh_user_id: row.user_id, file_manage_id: row.file_manage_id })
        resolve(result)
      } catch (error) {
        console.log(error)
      } finally {
        editLoading.value = false
      }
    })
  }

  // 拖动表头
  const headerDragend = (newWidth: number, oldWidth: number, column: any, event: any) => {
    // console.log(newWidth, oldWidth, column, event)
    const minWidth = column.minWidth || 40
    if (newWidth < column.minWidth) column.width = column.minWidth
  }

  return {
    handleAction,
    editBlack,
    headerDragend
  }
}